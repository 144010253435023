export enum DefaultRoles {
  Root = "ROLE_ROOT",
  SuperAdmin = "ROLE_2J",
  Manager = "ROLE_MANAGER",
  ET = "ROLE_ET",
  Admin = "ROLE_ADMIN",
  Trainer = "ROLE_TUTOR",
  Learner = "ROLE_LEARNER"
}

export const DEFAULT_ROLES = Object.entries(DefaultRoles).map(([_, identifier]) => identifier);
export const ROLES_2J = [DefaultRoles.Root, DefaultRoles.SuperAdmin, DefaultRoles.Manager, DefaultRoles.ET];
export const ROLES_2J_MANAGERS = [DefaultRoles.Manager, DefaultRoles.ET];
export const ROLES_2J_ET = ROLES_2J.filter((r) => r !== DefaultRoles.Manager);
export const ROLE_2J_ITEMS = [
  DefaultRoles.Root,
  DefaultRoles.SuperAdmin,
  DefaultRoles.Manager,
  DefaultRoles.ET
] as const;
export const ROLES_2J_ADMINS = [DefaultRoles.Root, DefaultRoles.SuperAdmin];

export interface Role {
  identifier: string;
  name: string;
  hierarchyElementId: number | null;
  managedIds: string[];
}

export interface Permission {
  identifier: string;
  name: string;
  description?: string;
}

export type RoleWithGrants = Role & {
  grants: Array<{
    roleId: string;
    permissionId: string;
    permission: Permission;
  }>;
};

export enum AvailablePermissions {
  ManageRole = "manage-role",
  ManageRoleReadonly = "manage-role-readonly",
  CourseListingSessions = "course-listing-sessions",
  CourseNew = "course-new",
  CourseDelete = "course-delete",
  CourseSettingsAttestation = "course-settings-attestation",
  CourseSettingsMasteryScore = "course-settings-masteryscore",
  CourseSettingsDuration = "course-settings-duration",
  CourseSettingsPrerequisite = "course-settings-prerequisite",
  CourseSettingsAttestationAvailaibility = "course-settings-attestation-availaibility",
  LearningPathNew = "learning-path-new",
  LearningPathDelete = "learning-path-delete",
  LearningPathArchive = "learning-path-archive",
  LearningPathInfo = "learning-path-info",
  LearningPathSettingsAttestation = "learning-path-settings-attestation",
  LearningPathSettingsPrerequisite = "learning-path-settings-prerequisite",
  LearningPathSettingsAttestationOnly = "learning-path-settings-attestation-only",
  ManageExtrafields = "manage-extrafields",
  ManageExtrafieldsReadonly = "manage-extrafields-readonly",
  HierarchyElementInfo = "hierarchy-element-info",
  HierarchyElementInfoReadonly = "hierarchy-element-info-readonly",
  HierarchyElementAppearance = "hierarchy-element-appearance",
  HierarchyElementContacts = "hierarchy-element-contacts",
  HierarchyElementContactsReadonly = "hierarchy-element-contacts-readonly",
  HierarchyStructure = "hierarchy-structure",
  HierarchyStructureReadonly = "hierarchy-structure-readonly",
  HierarchyElementListing = "hierarchy-element-listing",
  HierarchyElementNew = "hierarchy-element-new",
  HierarchyElementDelete = "hierarchy-element-delete",
  HierarchyElementArchive = "hierarchy-element-archive",
  HierarchyElementAlerts = "hierarchy-element-alerts",
  HierarchyElementAlertsReadonly = "hierarchy-element-alerts-readonly",
  HierarchyElementSettingsSessionContent = "hierarchy-element-settings-session-content",
  HierarchyElementSettingsSessionCustomAttestation = "hierarchy-element-settings-session-customattestation",
  Quota = "quota",
  QuotaManage = "quota-manage",
  HierarchyElementStats = "hierarchy-element-stats",
  SessionReadAny = "session-read-any",
  SessionWriteAny = "session-write-any",
  SessionWriteAnyTutor = "session-write-any-tutor",
  SessionDeleteAny = "session-delete-any",
  SessionArchiveAny = "session-archive-any",
  SessionReadSelf = "session-read-self",
  SessionWriteSelfCourseAdd = "session-write-self-course-add",
  SessionWriteSelfCourseDelete = "session-write-self-course-delete",
  SessionWriteSelfLearnerAdd = "session-write-self-learner-add",
  SessionWriteSelfLearnerDelete = "session-write-self-learner-delete",
  SessionWriteSelfTutor = "session-write-self-tutor",
  SessionWriteSelf = "session-write-self",
  SessionDeleteSelf = "session-delete-self",
  SessionArchiveSelf = "session-archive-self",
  SessionAddResults = "session-add-results",
  SessionEvalLaunch = "session-eval-launch",
  SessionAlerts = "session-alerts",
  UsersListing = "users-listing",
  UsersAdd = "users-add",
  UsersWrite = "users-write",
  UsersDelete = "users-delete",
  UsersDisable = "users-disable",
  UsersMove = "users-move",
  UsersControlDuplicate = "users-control-duplicate",
  LearnerAccess = "learner-access",
  QuizNew = "quiz-new",
  QuizDelete = "quiz-delete",
  QuizArchive = "quiz-archive",
  QuizUpdate = "quiz-update",
  SkillTableNew = "skilltable-new",
  SkillTableDelete = "skilltable-delete",
  SkillTableArchive = "skilltable-archive",
  SkillTableUpdate = "skilltable-update"
}

export type RuleContext = Record<string | number, any>;
export type RuleVariables = Record<string, any>;
export enum CurrentView {
  DEFAULT,
  LEARNER
}
