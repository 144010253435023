import { QueryClient } from "@tanstack/react-query";

import { http } from "./http";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: "always",
      queryFn: async ({ queryKey }) => {
        const data = await http.get(`${queryKey[0]}`).json();
        return data;
      }
    }
  }
});
